var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h6',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.detailDepartureSchedule.licensePlates)+" - "+_vm._s(_vm._dayjs(_vm.detailDepartureSchedule.timeStart).format("HH:mm DD/MM/YYYY"))+" - "+_vm._s(_vm.detailDepartureSchedule.from)+" - "+_vm._s(_vm.detailDepartureSchedule.to)+" ")]),_c('b-row',{staticClass:"gx-2 py-2"},[_c('b-col',[_c('div',{staticClass:"card-action vertical",on:{"click":function($event){_vm.detailDepartureSchedule.status == 'WAITING'
            ? (_vm.sheduleStatusDialog = true)
            : ''}}},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"bx bx-trip"})]),_c('div',{staticClass:"title"},[_vm._v("Dừng hoạt động")])])]),_c('b-col'),_c('b-col'),_c('b-col')],1),(!_vm.isLoading)?_c('vs-table',{staticClass:"table-schedule-detail",attrs:{"striped":""},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('vs-tr',[_c('vs-th',{staticStyle:{"max-width":"50px"}},[_vm._v("STT")]),_c('vs-th',{staticStyle:{"width":"40%"}},[_vm._v("SỐ ĐIỆN THOẠI")]),_c('vs-th',{staticStyle:{"width":"10%"}},[_vm._v("NGƯỜI")]),_c('vs-th',{staticStyle:{"width":"30%"}},[_vm._v("ĐIỂM ĐÓN")]),_c('vs-th',{staticStyle:{"width":"30%"}},[_vm._v("ĐIỂM TRẢ")])],1)]},proxy:true},{key:"tbody",fn:function(){return [_vm._l((_vm.listBooking),function(tr,i){return _c('vs-tr',{key:i,scopedSlots:_vm._u([{key:"expand",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('vs-button',{attrs:{"flat":"","icon":"","danger":"","active":true,"href":'tel:' + tr.acf.otherPhone
                    ? tr.acf.otherPhone
                    : tr.acf.userName}},[_c('i',{staticClass:"bx bxs-phone-call"})]),(tr.acf.otherPhone)?_c('vs-button',{attrs:{"flat":"","icon":"","primary":"","active":true,"href":'tel:' + tr.acf.userName}},[_c('i',{staticClass:"bx bxs-phone-call"})]):_vm._e()],1),_c('div',[(
                  _vm.detailDepartureSchedule.status == 'ONGOING' &&
                  tr.acf.status == 'ONCAR'
                )?_c('vs-button',{attrs:{"active":false,"border":"","info":""},on:{"click":function($event){_vm.paymentBookingId = tr.ID;
                  _vm.paymentDialog = true;}}},[_vm._v(" Thu cước ")]):_vm._e(),(
                  _vm.detailDepartureSchedule.status == 'ONGOING' &&
                  tr.acf.status == 'BOOKED'
                )?_c('vs-button',{attrs:{"border":"","active":false},on:{"click":function($event){_vm.onCarBookingId = tr.ID;
                  _vm.onCarDialog = true;}}},[_vm._v(" Đã đón ")]):_vm._e(),(
                  _vm.detailDepartureSchedule.status !== 'COMPLETED' &&
                  tr.acf.status == 'BOOKED'
                )?_c('vs-button',{attrs:{"active":false,"border":"","danger":""},on:{"click":function($event){_vm.cancelBookingId = tr.ID;
                  _vm.cancelDialog = true;}}},[_vm._v(" Khách huỷ ")]):_vm._e()],1)])]},proxy:true}],null,true)},[_c('vs-td',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"status",class:tr.acf.status}),_vm._v(" "+_vm._s(i + 1)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.acf.userName)+" ")]),_c('vs-td',[_c('i',{staticClass:"bx bx-male"}),_vm._v(_vm._s(tr.acf.numOfAdult)+"+"+_vm._s(tr.acf.numOfChild))]),_c('vs-td',[_vm._v(" "+_vm._s(tr.acf.pickupPoint)+" ")]),_c('vs-td',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"payment",class:tr.acf.amount ? 'paid' : ''}),_vm._v(" "+_vm._s(tr.acf.dropPoint)+" ")])],1)}),_vm._l((_vm.listPackage.data),function(item){return _c('vs-tr',{key:item.ID},[_c('vs-td',{attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(item.post_title))]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.priceFormat(item.acf.amount))+" ")])],1)}),_c('vs-tr',[_c('vs-td',{attrs:{"colspan":"4"}},[_c('b',[_vm._v("Tổng tiền")])]),_c('vs-td',[_c('b',[_vm._v(_vm._s(_vm.totalAmount()))])])],1)]},proxy:true}],null,false,3592517592)}):_vm._e(),_c('vs-dialog',{attrs:{"blur":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',[_vm._v("Dừng chuyến đi này ?")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer-dialog"},[_c('vs-button',{attrs:{"block":"","size":"large","danger":""},on:{"click":function($event){return _vm.inActiveSchedule()}}},[_vm._v(" Xác nhận ")])],1)]},proxy:true}]),model:{value:(_vm.sheduleStatusDialog),callback:function ($$v) {_vm.sheduleStatusDialog=$$v},expression:"sheduleStatusDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }