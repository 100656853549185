import { booking } from "@/api/booking.js";
import { departureScheduleApi } from "@/api/departureSchedule.js";
import dayjs from "dayjs";
export default {
  name: "ScheduleDetail",
  components: {},
  data: () => ({
    value: "",
    sheduleStatusDialog: false,
    phoneNumber: "",
    amount: 150,
    paymentMethod: "cash",
    listBooking: [],
    detailDepartureSchedule: {},
    confirmUpdateStatus: false,
    isLoading: false,
    fuelAmount: 500,
    packageAmount: 50,
    listNumOfAdult: [1, 2, 3, 4, 5],
    listNumOfChild: [0, 1, 2, 3],
    numOfAdult: 1,
    numOfChild: 0,
    pickupPoint: "",
    dropPoint: "",
    listPackage: null,
  }),
  async created() {
    const loading = this.$vs.loading();
    await this.getListBooking();
    await this.getDetailDepartureSchedule();
    await this.getListPackage();
    loading.close();
  },
  methods: {
    async getListBooking() {
      await booking
        .getListBooking({ departureScheduleId: this.$route.params.id })
        .then((res) => {
          this.listBooking = res;
        });
    },
    async getListPackage() {
      await departureScheduleApi
        .getListPackage({
          departureScheduleId: this.$route.params.id,
        })
        .then((res) => {
          this.listPackage = res;
        });
    },
    async getDetailDepartureSchedule() {
      await departureScheduleApi
        .getDetailDepartureSchedule({
          departureScheduleId: this.$route.params.id,
        })
        .then((res) => {
          this.detailDepartureSchedule = res;
        });
    },
    async onCarBooking(Id) {
      const loading = this.$vs.loading();
      this.isLoading = true;
      let params = {
        bookingId: Id,
      };

      await booking.onCarBooking(params).then((res) => {
        this.onCarDialog = false;
        if (res.code == "SUCCESS") {
          this.$vs.notification({
            color: "success",
            position: "top-right",
            duration: "2000",
            title: "Huỷ ghế thành công !",
          });
          this.getListBooking();
        }
      });
      this.isLoading = false;
      // Tắt loading
      loading.close();
    },
    async cancelBooking(Id) {
      const loading = this.$vs.loading();
      this.isLoading = true;
      let params = {
        bookingId: Id,
      };

      await booking.cancelBooking(params).then((res) => {
        this.cancelDialog = false;
        if (res.code == "SUCCESS") {
          this.$vs.notification({
            color: "success",
            position: "top-right",
            duration: "2000",
            title: "Huỷ ghế thành công !",
          });
          this.getListBooking();
        }
      });
      this.isLoading = false;
      // Tắt loading
      loading.close();
    },
    async addPaymentBooking(Id) {
      const loading = this.$vs.loading();
      this.isLoading = true;
      let params = {
        bookingId: Id,
        amount: this.amount * 1000,
        paymentMethod: this.paymentMethod,
      };
      await booking.addPaymentBooking(params).then((res) => {
        this.cancelDialog = false;
        if (res.code == "SUCCESS") {
          this.$vs.notification({
            color: "success",
            position: "top-right",
            duration: "2000",
            title: "Thu cước thành công !",
          });
          this.getListBooking();
          this.paymentDialog = false;
        }
      });

      this.isLoading = false;
      loading.close();
    },
    async updateStatusSchedule() {
      let status;
      if (this.detailDepartureSchedule.status == "WAITING") {
        status = "ONGOING";
      } else if (this.detailDepartureSchedule.status == "ONGOING") {
        status = "COMPLETED";
      }
      const loading = this.$vs.loading();
      this.isLoading = true;
      await departureScheduleApi
        .updateStatusDepartureSchedule({
          departureScheduleId: this.$route.params.id,
          status: status,
        })
        .then((res) => {
          if (res) {
            this.getDetailDepartureSchedule();
            this.isLoading = true;
            this.isLoading = false;
            this.$vs.notification({
              color: "success",
              position: "top-right",
              duration: "2000",
              title: "Trạng thái đã được thay đổi thành đang di chuyển",
            });
            this.sheduleStatusDialog = false;
          }
        });

      this.isLoading = false;
      // Tắt loading
      loading.close();
    },

    async addBuyFuel() {
      const loading = this.$vs.loading();
      this.isLoading = true;
      await departureScheduleApi
        .addBuyFuel({
          departureScheduleId: this.$route.params.id,
          fuelAmount: this.fuelAmount * 1000,
        })
        .then((res) => {
          if (res) {
            this.getDetailDepartureSchedule();
            this.isLoading = true;
            this.isLoading = false;
            this.$vs.notification({
              color: "success",
              position: "top-right",
              duration: "2000",
              title: "Thêm tiền xăng/dầu thành công",
            });
            this.buyFuelDialog = false;
          }
        });

      this.isLoading = false;
      // Tắt loading
      loading.close();
    },

    async addPackage() {
      const loading = this.$vs.loading();
      this.isLoading = true;
      await departureScheduleApi
        .addPackage({
          departureScheduleId: this.$route.params.id,
          packageAmount: this.packageAmount * 1000,
        })
        .then((res) => {
          if (res) {
            this.getDetailDepartureSchedule();
            this.getListPackage();
            this.isLoading = true;
            this.isLoading = false;
            this.$vs.notification({
              color: "success",
              position: "top-right",
              duration: "2000",
              title: "Thêm tiền xăng/dầu thành công",
            });
            this.addPackageDialog = false;
          }
        });

      this.isLoading = false;
      // Tắt loading
      loading.close();
    },
    async addUnknownBooking() {
      const loading = this.$vs.loading();
      this.isLoading = true;
      let body = {
        numOfAdult: this.numOfAdult,
        numOfChild: this.numOfChild,
        pickupPoint: this.pickupPoint,
        dropPoint: this.dropPoint,
        departureScheduleId: this.$route.params.id,
        otherPhone: "",
      };
      await booking.createBooking(body).then((res) => {
        if (res.id) {
          this.$vs.notification({
            color: "success",
            position: "top-right",
            duration: "2000",
            title: "Đặt ghế thành công !",
          });
          this.getListBooking();
          this.addPassengerDialog = false;
        }
      });
      this.isLoading = false;
      loading.close();
    },
    _dayjs: dayjs,
    totalAmount() {
      let total = 0;
      this.listBooking.forEach((element) => {
        if (element.acf.status == "ONCAR" && element.acf.amount) {
          total = total + parseInt(element.acf.amount);
        }
      });
      return this.priceFormat(total + this.listPackage.total);
    },
  },
};
